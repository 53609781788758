import type {DeviceState} from '@prisma/client'
import {DeviceType, Product} from '@prisma/client'
import browser from 'browser-detect'
import {getMessaging, getToken, isSupported, onMessage} from 'firebase/messaging'
import React from 'react'
import firebase, {firebaseConfig} from './firebase'

const updateRaven = async (messageId: string) => {
  const pushEndpoint = `${process.env.WEBPUSH_ENDPOINT}/click`
  await fetch(pushEndpoint, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({messageId}),
  })
}

const checkNotificationPermissions = () => {
  return Notification.permission
}

const registerAndGetToken = async () => {
  // ? background notifications will only focus tab with the same host if any is already open. We force redirection here
  // https://github.com/firebase/firebase-js-sdk/issues/3922#issuecomment-1197002484
  navigator.serviceWorker.onmessage = async event => {
    if (event.data.messageType === 'notification-clicked') {
      const messageId = event.data.fcmMessageId
      await updateRaven(messageId)
      const currentPage = window.location.href.replace(window.location.origin, '')
      if (currentPage === event.data.notification.click_action) {
        return
      }
      window.location.href = event.data.notification.click_action
    }
  }

  const messaging = getMessaging(firebase)

  const serviceWorkerRegistration = await navigator.serviceWorker.register('/sw.js')

  await navigator.serviceWorker.ready

  const result = await getToken(messaging, {
    vapidKey: firebaseConfig.vapidKey,
    serviceWorkerRegistration,
  })

  return result
}

const getDeviceId = () => {
  let machineId = localStorage.getItem('firebaseDeviceId')
  if (!machineId) {
    machineId = crypto.randomUUID()
    localStorage.setItem('firebaseDeviceId', machineId)
  }
  return machineId
}

const getDeviceInfo = () => {
  const result = browser()
  return {
    browserName: result.name,
    browserVersion: result.version,
    os: result.os,
  }
}

export const requestNotificationPermissions = async () => {
  return Notification.requestPermission()
}

export const getDeviceState = async (): Promise<DeviceState> => {
  if (!(await isSupported())) {
    return 'UNSUPPORTED'
  }
  const permission = checkNotificationPermissions()

  if (permission === 'denied') {
    return 'DENIED'
  }
  if (permission === 'granted') {
    return 'GRANTED'
  }

  return 'INITIAL'
}

export const getUpdateDevicePayload = async () => {
  const deviceState = await getDeviceState()
  return {
    product: Product.BO,
    type: DeviceType.WEB,
    appVersion: process.env.version,
    deviceId: getDeviceId(),
    state: deviceState,
    ...getDeviceInfo(),
    fcmToken: deviceState === 'GRANTED' ? await registerAndGetToken() : null,
  }
}

// ? background notifications are already handled by default
// ? this onMessage listener will only be triggered if the app has focus
// https://firebase.google.com/docs/cloud-messaging/js/receive#web-namespaced-api_3
export const useForegroundNotifications = () => {
  const [localIsSupported, setIsSupported] = React.useState(false)

  React.useEffect(() => {
    const asyncIsSupp = async () => {
      setIsSupported(await isSupported())
    }
    asyncIsSupp()
  }, [])

  React.useEffect(() => {
    if (!localIsSupported) {
      return null
    }

    const messaging = getMessaging(firebase)
    const unlisten = onMessage(messaging, async payload => {
      try {
        const {notification, fcmOptions, messageId} = payload
        // ? tag is used to prevent multiple notifications for the same message
        // ? as this event listener can be register on more than one tab.
        const notif = new Notification(notification.title, {...notification, tag: messageId})
        notif.onclick = async () => {
          await updateRaven(messageId)
          window.open(fcmOptions.link, '_blank')
        }
      } catch (_err) {
        // ! mobile chrome does not support new Notification() calls
        // https://issues.chromium.org/issues/40415865
        // as this method is only intended to display foreground notifications, this is a no op
        // mobile users should use the native app for full notification support
      }
    })

    return unlisten
  }, [localIsSupported])
}
