import {useRouter} from 'next/router'

export type ProductType = 'A4D' | 'BO'
export const useProduct = () => {
  const router = useRouter()
  const product = (() => {
    if (router.pathname.startsWith('/a4d') || router.pathname.startsWith('/r')) {
      return 'A4D'
    }

    return 'BO'
  })()

  return product
}
