import React from 'react'
import styled, {css, createGlobalStyle} from 'styled-components'
import {VSpace, FlatButton} from '@ambler/andive'

import {Visible} from 'react-grid-system'
import type {FixType} from '@ambler/shared'
import {Text, palette, Andiv, ArrowDownIcon, ArrowUpIcon} from '@ambler/andive-next'
import {ZIndexes} from '../constants/enum'
import {AppCol, AppRow, useMobile} from './stack/responsive'
import {Avatar} from './avatar'

export const Flex = styled.div`
  flex: 1;
`

export const Divider = styled.div<{$vpadding?: number; $isSmall?: boolean; $color?: string}>`
  width: 100%;
  height: ${({$vpadding}) => ($vpadding ? $vpadding * 2 + 1 : 17)}px;

  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% + ${({$isSmall}) => ($isSmall ? -16 : 32)}px);
    height: 1px;
    top: ${({$vpadding}) => $vpadding || 8}px;
    left: ${({$isSmall}) => ($isSmall ? 8 : -16)}px;
    background: ${({$color}) => $color ?? palette.amblea.grey[400]};
  }
`

export const VDivider = styled.div<{$vpadding?: number}>`
  width: 1px;

  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + ${({$vpadding}) => ($vpadding ? $vpadding * 2 : 24)}px);
    top: ${({$vpadding}) => ($vpadding ? -$vpadding : -8)}px;
    left: 0;
    background: ${palette.amblea.grey[400]};
  }
`

export const Card = styled(Andiv).attrs({
  column: true,
  p: '16px',
  bg: palette.amblea.white,
  borderRadius: '4px',
  border: `1px solid ${palette.amblea.grey[400]}`,
})``

export const RowCard = styled.div`
  padding: 8px 16px;
  border-radius: 16px;
  border: solid 1px ${palette.amblea.grey[400]};
  background-color: ${palette.amblea.white};

  :hover {
    background-color: ${palette.amblea.grey[100]};
  }
`

const IconPadding = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const Row = styled.div<{$justify?: string}>`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({$justify}) => $justify ?? 'flex-start'};
  align-items: center;
`

export function FoldingCard({title, children, defaultOpen = false}: FixType) {
  const [open, setOpen] = React.useState(defaultOpen)

  React.useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  return (
    <Card>
      <Andiv style={{cursor: 'pointer'}} onClick={() => setOpen((prev: FixType) => !prev)} justify="space-between">
        <Andiv column p="8px">
          <Text t="h3">
            <b>{title}</b>
          </Text>
        </Andiv>
        <IconPadding>
          {!open ? (
            <ArrowDownIcon color={palette.amblea.grey[600]} />
          ) : (
            <ArrowUpIcon color={palette.amblea.grey[600]} />
          )}
        </IconPadding>
      </Andiv>
      {open ? children : null}
    </Card>
  )
}

export function RowWith2Col({left, right}: FixType) {
  const isMobile = useMobile()

  return (
    <AppRow>
      <AppCol md={6} style={{paddingRight: isMobile ? 0 : 4}} align="start">
        <Flex>
          {left}
          <Visible xs sm>
            <VSpace $px={8} />
          </Visible>
        </Flex>
      </AppCol>

      {right ? (
        <AppCol md={6} style={{paddingLeft: isMobile ? 0 : 4}} align="start">
          <Flex>{right}</Flex>
        </AppCol>
      ) : null}
    </AppRow>
  )
}

export function NullableField({test, children, truncate = false}: FixType) {
  if (test) {
    return children()
  }

  return (
    <Text truncate={truncate} t="body2" color={palette.amblea.grey[600]}>
      −
    </Text>
  )
}

export const HSpace = styled.div<{$px: number}>`
  width: ${({$px}) => $px}px;
`

export const HDivider = styled.div`
  position: relative;

  height: 16px;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    left: 8px;
    top: 8px;

    background: ${palette.amblea.grey[400]};
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

export const CardLabelCol = styled(AppCol)`
  padding-left: 56px !important;
`

export function Gravatar({email}: {email: string}) {
  if (!email) {
    return (
      <Andiv column p="8px">
        <Text t="body3">N/A</Text>
      </Andiv>
    )
  }

  return <Avatar email={email} size={35} />
}

const TooltipPanel = styled.div<{$openDown?: boolean}>`
  position: absolute;

  min-width: 200px;
  bottom: ${({$openDown}) => ($openDown ? 'inherit' : '100%')};
  top: ${({$openDown}) => ($openDown ? '100%' : 'inherit')};
  right: 8px;

  padding: 8px;

  border-radius: 8px;
  background: ${palette.amblea.blue[800]};
  color: white;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  z-index: ${ZIndexes.FIXED};
`

const TooltipRoot = styled.div`
  position: relative;

  & ${TooltipPanel} {
    display: none;
  }

  :hover {
    & ${TooltipPanel} {
      display: block;
    }
  }
`

export function Tooltip({
  tooltip,
  children,
  openDown = false,
}: {
  tooltip: JSX.Element
  children: React.ReactNode
  openDown?: boolean
}) {
  return (
    <TooltipRoot>
      <TooltipPanel $openDown={openDown}>{tooltip}</TooltipPanel>
      {children}
    </TooltipRoot>
  )
}

export const ListHeader = styled(AppRow)``

export const ListCard = styled.div<{$isMobile?: boolean; $disabled?: boolean}>`
  display: block;
  background-color: ${palette.amblea.white};

  border: 1px solid ${palette.amblea.grey[400]};
  border-radius: 16px;
  padding: ${({$isMobile}) => ($isMobile ? 16 : 8)}px 16px;
  overflow: hidden;
  position: relative;

  ${({$disabled}) =>
    !$disabled &&
    css`
      cursor: pointer;
      :hover {
        text-decoration: none;
        background-color: ${palette.amblea.grey[100]};
        ::before {
          content: '';
          background: ${palette.amblea.grey[100]};
          width: calc(100% + 16px);
          height: calc(100% + 16px);
          z-index: -1;

          position: absolute;
          left: -8px;
          top: -16px;
        }
      }
    `}
`

export const FormBaseline = createGlobalStyle`
  body {
    background: white !important;
  }
`

export const Title: React.FC = ({children}) => {
  const isMobile = useMobile()
  return (
    <Text t={isMobile ? 'h3' : 'h2'} truncate>
      <b>{children}</b>
    </Text>
  )
}

export const MobileIconButton = styled(({...props}) => <FlatButton {...props} />)`
  padding: 0px !important;
`

const Field = ({value}: {value: string | string[]}) => {
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((value_, index) => (
          <Text t="body3" key={index}>
            {value_}
          </Text>
        ))}
      </>
    )
  }

  return <Text t="body3">{value}</Text>
}

export function FieldValueCol({label, value, md}: {label: string; value?: string | string[]; md?: number}) {
  const isMobile = useMobile()

  return (
    <>
      {isMobile && (
        <AppCol xs={6}>
          <Andiv column p="8px">
            <Text t="body3" color={palette.amblea.grey[600]}>
              {label}
            </Text>
          </Andiv>
        </AppCol>
      )}
      <AppCol xs={6} md={md || 2}>
        <Andiv column p="8px">
          <NullableField truncate test={Boolean(value)}>
            {() => <Field value={value} />}
          </NullableField>
        </Andiv>
      </AppCol>
    </>
  )
}
