import {initializeApp} from 'firebase/app'

export const firebaseConfig = {
  apiKey: 'AIzaSyDQ_33mWASycUQqAQEY9swYqr5F4KKHonk',
  authDomain: 'amblea.firebaseapp.com',
  projectId: 'amblea',
  storageBucket: 'amblea.appspot.com',
  messagingSenderId: '172156683439',
  vapidKey: 'BE6L02UKykHylWN9n7agkoybRVS3E6vhlLtFTyYV5RdjbZ4uFTsimz3z_4NrM_846v1xEFoFXM7YZQ41Sc16AO0',
  appId: process.env.FIREBASE_APP_ID,
}

const firebase = initializeApp(firebaseConfig)

export default firebase
