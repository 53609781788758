import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import {palette} from '@ambler/andive-next'

const Wrapper = styled.div<{$size: AvatarProps['size']}>`
  height: ${p => p.$size}px;
  width: ${p => p.$size}px;
  background: white;
  box-shadow: inset 0 0 0 1px ${palette.amblea.grey[200]};
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`

type AvatarProps = {
  email: string
  size?: number
}

export function Avatar(props: AvatarProps) {
  const size = props?.size ?? 32

  return (
    <Wrapper $size={size}>
      <Image
        src={`${process.env.AVATAR_ENDPOINT}/${encodeURIComponent(props.email)}`}
        layout="fixed"
        width={size}
        height={size}
      />
    </Wrapper>
  )
}
