import {pdfjs} from 'react-pdf'

// ? https://github.com/wojtekmaj/react-pdf#nextjs
// ? rest of the config is in next.config.js
// ? search for "Load react-pdf worker files"

// @ts-ignore
import workerSrc from 'pdfjs-dist/build/pdf.worker.js'

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc
