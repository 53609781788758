// **
// * /!\ POLYFILLS MUST BE AT THE TOP-LEVEL
// **
import '../lib/polyfills'
// **
// * /!\ POLYFILLS MUST BE AT THE TOP-LEVEL
// **

import React from 'react'
import {gql, useQuery} from '@apollo/client'
import Head from 'next/head'
import {Baseline, Loader} from '@ambler/andive'
import styled, {createGlobalStyle} from 'styled-components'
import {Auth0Provider} from '@auth0/auth0-react'
import type {FixType} from '@ambler/shared'
import {useRouter} from 'next/router'
import {Andiv, palette, Text, Toaster} from '@ambler/andive-next'
import {useAuth, useForcedMtId, useMainMt, isStaffEmail} from '../components/stack/auth'
import {useProduct} from '../components/stack/product'
import '../lib/pdfjs-init'
import {ResponsiveProvider} from '../components/stack/responsive'
import {ReactDatesAmblerTheme, ReactDatesDatePickerCss} from '../components/datetime-filter'
import {withAsideLoader} from '../components/aside-loader'
import {FaviconProvider} from '../components/favicon'
import {Updater} from '../components/app-updater'
import Sentry from '../lib/sentry'
import {auth0Params} from '../lib/auth'
import {CanGoBackProvider} from '../components/stack/router'
import {withApollo} from '../components/stack/apollo/with-apollo'
import {useMutation} from '../hooks/use-mutation'
import {getUpdateDevicePayload, useForegroundNotifications} from '../lib/firebase-messaging'
import type {
  BO_StackAuthMtName_,
  BO_UpdatePushNotificationsDeviceMutation_,
  BO_UpdatePushNotificationsDeviceMutationVariables,
} from './_app.generated'

const mtNameQuery = gql`
  query BO_StackAuthMtName($mtId: ID!) {
    medicalTransporter(id: $mtId) {
      id
      name
    }
  }
`

const updatePushNotificationsDeviceMutation = gql`
  mutation BO_UpdatePushNotificationsDeviceMutation($data: PushNotificationsUpsertDeviceDataInput!) {
    pushNotificationsUpsertDevice(data: $data)
  }
`

const BoBaseline = createGlobalStyle`
  body {
    background: ${palette.amblea.grey[100]};
  }
`

const GlowingBorder = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border: 8px solid rgba(229, 181, 135, 0.8);
  z-index: 99999;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
`
const ConnectAsOverlay = ({asName}: {asName: string}) => (
  <GlowingBorder>
    <Andiv p="8px" borderRadius="4px 0 0 0" bg="rgba(229, 181, 135, 0.8)">
      <Text t="body2">Connecté en tant que : </Text>&nbsp;
      <Text t="h3">
        <b>{asName}</b>
      </Text>
    </Andiv>
  </GlowingBorder>
)

const FirebaseProvider: React.FC = ({children}) => {
  const [deviceInitialized, setDeviceInitialized] = React.useState(false)
  const {isAuthenticated} = useAuth()

  const [updateDevice] = useMutation<
    BO_UpdatePushNotificationsDeviceMutation_,
    BO_UpdatePushNotificationsDeviceMutationVariables
  >({
    mutation: updatePushNotificationsDeviceMutation,
  })

  React.useEffect(() => {
    const initDevice = async () => {
      await updateDevice({
        variables: {
          data: await getUpdateDevicePayload(),
        },
      })
    }
    if (isAuthenticated && !deviceInitialized) {
      initDevice()
      setDeviceInitialized(true)
    }
  }, [isAuthenticated, updateDevice, deviceInitialized])

  useForegroundNotifications()

  return <>{children}</>
}

const AuthenticatedApp: React.FC = ({children}) => {
  const [loading, setLoading] = React.useState(true)
  const router = useRouter()
  const product = useProduct()
  const mainMt = useMainMt()
  const {user, mtAcls, isAuthenticated, isEmailConfirmed, isLoading, signIn, signOut, isConnectAs} = useAuth()
  const [forcedMtId, forcedMtIdLoading] = useForcedMtId(user?.email)

  const isErrorPage = router.pathname === '/auth/error'
  const isNotVerifiedPage = router.pathname === '/auth/not_verified'
  const error = router.query['error']
  const errorCode = router.query['error_description']

  const mtNamequeryResult = useQuery<BO_StackAuthMtName_>(mtNameQuery, {
    variables: {mtId: mainMt?.mt.id},
    skip: !mainMt || !isConnectAs,
    fetchPolicy: 'cache-first',
  })

  React.useEffect(() => {
    if (isAuthenticated) {
      Sentry.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        extra: {
          isEmailConfirmed,
          role: user.role,
          mtAcls: JSON.stringify(mtAcls, null, 2), // if we don't do this, Sentry will not show the nested object
        },
      })
    }
  }, [user, isAuthenticated, isEmailConfirmed, mtAcls])

  React.useEffect(() => {
    if (!router.isReady) {
      return
    }
    if (isLoading || forcedMtIdLoading) {
      return
    }
    if (!forcedMtId && router?.query.forceMt) {
      // ? A4D: we just oppened a new tab but the forcedMtId is not yet set. We want to avoid the redirect to '/'
      return
    }
    if (error && !isErrorPage) {
      router.replace(`/auth/error?error=${errorCode}`)
      return
    }
    if (isErrorPage || isNotVerifiedPage) {
      // ? we don't want those pages to be auth protected.
      setLoading(false)
      return
    }
    if (!isAuthenticated) {
      // ? callbackUrl: we redirect to the previously visited page after Auth0 login
      signIn({callbackUrl: `${process.env.AMBLER_BO_URL}?redirect=${router.asPath}`})
      return
    } else {
      if (!isEmailConfirmed && !isNotVerifiedPage) {
        router.replace(`/auth/not_verified`)
        return
      }
      // ? When the user navigates to a page and is disconnected, we have stored the page URL before asking
      // ? him to connect and we can now redirect him to the expected page he wanted to visit.
      const previousPath = router.query['redirect'] as string
      if (previousPath && previousPath !== '/') {
        router.replace(previousPath)
        return
      }
      if (product === 'A4D') {
        if (!mainMt || mainMt.mt.type !== 'DISPATCHER') {
          router.replace('/')
          return
        }
      } else {
        // * BO
        if (mainMt && mainMt.mt.type === 'DISPATCHER') {
          router.replace('/a4d/dashboard')
          return
        }
        if (!isStaffEmail(user?.email)) {
          signOut()
          return
        }
      }
    }
    setLoading(false)
  }, [
    error,
    errorCode,
    isErrorPage,
    isAuthenticated,
    isLoading,
    mainMt,
    product,
    router,
    signIn,
    signOut,
    user,
    forcedMtIdLoading,
    forcedMtId,
    isNotVerifiedPage,
    isEmailConfirmed,
  ])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {isConnectAs && <ConnectAsOverlay asName={mtNamequeryResult?.data?.medicalTransporter?.name ?? '...'} />}
      {children}
    </>
  )
}

function BoApp({Component, pageProps, err}: FixType) {
  // injected err is a workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <>
      <Auth0Provider
        domain={auth0Params.domain}
        clientId={auth0Params.client_id}
        redirectUri={auth0Params.redirect_uri}
        scope={auth0Params.scope}
        cacheLocation={auth0Params.cacheLocation}
        useRefreshTokens={auth0Params.useRefreshTokens}
      >
        <Head>
          {/* 
          Empty title serve as a fallback here,
          When no title is define in the subsequent component tree
        */}
          <title></title>
          <link rel="manifest" href="/static/manifest.json" />
          <meta name="referrer" content="same-origin" />
          <link rel="icon" href="/static/favicon-64x64.png" type="image/x-png" />
        </Head>
        <ResponsiveProvider>
          <CanGoBackProvider>
            <FaviconProvider>
              <BoBaseline />
              <Baseline>
                <>
                  <Toaster />
                  <Updater />
                  <AuthenticatedApp>
                    <FirebaseProvider>
                      <Component {...pageProps} err={err} />
                    </FirebaseProvider>
                  </AuthenticatedApp>
                </>
              </Baseline>
              <ReactDatesAmblerTheme />
              <ReactDatesDatePickerCss />
            </FaviconProvider>
          </CanGoBackProvider>
        </ResponsiveProvider>
      </Auth0Provider>
    </>
  )
}

export default withAsideLoader(withApollo(BoApp))
