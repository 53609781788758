import {DocumentBodyPortal, palette} from '@ambler/andive-next'
import React from 'react'
import styled from 'styled-components'
import {Loader} from '@ambler/andive'
import {motion} from 'framer-motion'
import moment from 'moment'
import hoistNonReactStatics from 'hoist-non-react-statics'
import type {FixType} from '@ambler/shared'
import {ZIndexes} from '../constants/enum'
import {usePrevious} from '../hooks/use-previous'

const AsideLoaderAnchor = styled.div`
  z-index: ${ZIndexes.FIXED};
  position: fixed;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
`

const AnimatedCircle = styled(motion.div)`
  position: absolute;
  top: -74px;
  left: -58px;
  width: 42px;
  height: 42px;
  padding: 4px;
  background: ${palette.amblea.white};
  border: 1px solid ${palette.amblea.grey[400]};
  border-radius: 50%;
  box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
`

/**
 * Returns the `input` value, skipping its variations for `duration` seconds after
 * each true value is given.
 *
 * Marble diagram (one dash is 1 sec and `duration` is 3 sec)
 *  `input`       f----t----f-------t-f--t----->
 *   return       f----t----t>>>f---t-t>>>t---->
 *
 * Coupled with animated CSS, this enable to play an animation when the `input` boolean
 * switch from false -> true and keep it true as least as long as the animation plays.
 *
 * Note: this is probably 3 lines of rxjs.
 */
function useAnimatedBoolean(input: boolean, duration: number): boolean {
  const [{output, skipUntil}, setState] = React.useState({output: input, skipUntil: null})
  const latestValue = React.useRef(null)
  const timeoutHandle = React.useRef(null)
  const prevLoading = usePrevious(input)

  React.useEffect(() => {
    // ? After the `duration` timeout, we want to return the latest input value.
    // ? This is what makes the hook skip `input` values.
    latestValue.current = input

    // ? No need to trigger the skipping logic when the input has not change.
    if (prevLoading === input) {
      return
    }

    // ? false -> true.
    if (input && !output) {
      setState({output: true, skipUntil: moment().add(duration, 's')})

      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current)
      }

      // ? Wait for at least `duration` sec. (re-render me after skipUntil).
      timeoutHandle.current = setTimeout(
        () => setState(prev => ({output: latestValue.current, skipUntil: prev.skipUntil})),
        duration * 1000,
      )
      // ? Done with skipping values, set output to the latest value.
    } else if (skipUntil && moment() > skipUntil) {
      setState({output: false, skipUntil: null})
    }
  }, [duration, input, output, prevLoading, skipUntil])

  React.useEffect(() => {
    return () => {
      // Hook cleanup
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current)
      }
    }
  }, [])

  return output
}

const AsideLoader = ({isLoading}: {isLoading: boolean}) => {
  const isVisible = useAnimatedBoolean(isLoading, 1)
  const animate = isVisible ? 'visible' : 'hidden'
  return (
    <DocumentBodyPortal>
      <AsideLoaderAnchor>
        <AnimatedCircle
          variants={{hidden: {scale: 0}, visible: {scale: 1}}}
          animate={animate}
          transition={{duration: isVisible ? 0.8 : 0.25, ease: isVisible ? 'easeOut' : 'easeIn'}}
        >
          <motion.div variants={{hidden: {opacity: 0}, visible: {opacity: 1}}} animate={animate}>
            <Loader inline color={isLoading ? palette.amblea.blue[600] : palette.amblea.green[500]} />
          </motion.div>
        </AnimatedCircle>
      </AsideLoaderAnchor>
    </DocumentBodyPortal>
  )
}

const AsideLoaderContext = React.createContext({setupLoader() {}, cleanupLoader() {}})

const AsideLoaderProvider = ({children}: FixType) => {
  const [loaderCount, setLoaderCount] = React.useState(0)

  const value = React.useMemo(() => {
    return {
      setupLoader() {
        setLoaderCount(count => count + 1)
      },
      cleanupLoader() {
        setLoaderCount(count => (count === 0 ? 0 : count - 1))
      },
    }
  }, [])

  return (
    <>
      <AsideLoader isLoading={loaderCount > 0} />
      <AsideLoaderContext.Provider value={value}>{children}</AsideLoaderContext.Provider>
    </>
  )
}

export const useAsideLoader = (loading: FixType) => {
  const {setupLoader, cleanupLoader} = React.useContext(AsideLoaderContext)

  React.useEffect(() => {
    if (loading) {
      setupLoader()
    } else {
      cleanupLoader()
    }
  }, [cleanupLoader, loading, setupLoader])
}

export function withAsideLoader(Component: FixType) {
  function WithAsideLoader(props: FixType) {
    return (
      <AsideLoaderProvider>
        <Component {...props} />
      </AsideLoaderProvider>
    )
  }

  hoistNonReactStatics(WithAsideLoader, Component)

  return WithAsideLoader
}
