import {ApolloClient, InMemoryCache} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {createUploadLink} from 'apollo-upload-client'
import {Auth0Client} from '@auth0/auth0-spa-js'

import type {FixType} from '@ambler/shared'

import {ErrorCodeType} from '../../../constants/enum'
import Sentry, {generateTraceId} from '../../../lib/sentry'
import {auth0Params} from '../../../lib/auth'

const ssrMode = !process.browser

export function createApolloClient(initialState: FixType): ApolloClient<FixType> {
  const traceId = generateTraceId()
  const auth0Client = new Auth0Client(auth0Params)

  let authLink
  // ? ssr is not for authenticated calls at the moment
  if (!ssrMode) {
    authLink = setContext(async (_, {headers}) => {
      let token
      try {
        await auth0Client.getTokenSilently() // ? this will refresh the token if expired
        token = await auth0Client.getIdTokenClaims()
      } catch (error) {
        // ? in our case, 'login_required' happens when disconnected from another tab
        if (error.error !== 'login_required') {
          throw error
        }
        return {headers}
      }

      if (!token) {
        return {headers}
      }
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token.__raw}`,
        },
      }
    })
  }

  const uploadLink = createUploadLink({
    uri: process.env.GRAPHQL_ENDPOINT, // Server URL (must be absolute)
    headers: {
      'x-ambler-client-trace-id': traceId,
      'x-ambler-client-location': !ssrMode ? window.location.href : 'ssr', // TODO: remove from CORS as well
    },
  })

  // @ts-ignore FIX versions: https://github.com/apollographql/apollo-link/issues/538
  const link = ssrMode ? uploadLink : authLink.concat(uploadLink)
  const errorLink = onError(({operation, graphQLErrors, networkError}) => {
    if (networkError) {
      console.error('networkError', networkError)
      return
    }

    let shouldDisconnect = false
    // ? Find any unknown error.
    if (graphQLErrors) {
      graphQLErrors.forEach(err => {
        const isKnownError =
          (err.extensions && err.extensions.code && Object.keys(ErrorCodeType).includes(err.extensions.code)) || false

        if (err?.extensions?.code === 'INTERNAL_SERVER_ERROR' && err.message === 'Not Authorised!') {
          shouldDisconnect = true
        }

        if (!isKnownError) {
          Sentry.withScope(scope => {
            scope.setTag('operation', operation.operationName)
            scope.setTag('client_trace_id', traceId)
            Sentry.captureException(new Error(`${operation.operationName}: ${err.message}`))
          })
        }
      })
    }
    if (shouldDisconnect) {
      window.location.href = '/'
    }
  })

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode, // Disables forceFetch on the server (so queries are only run once)
    // @ts-ignore FIX versions: https://github.com/apollographql/apollo-link/issues/538
    link: !ssrMode ? errorLink.concat(link) : link,
    name: `${process.env.AMBLER_STAGE}_BO`,
    version: process.env.version,
    cache: new InMemoryCache().restore(initialState || {}),
  })
}
